<template>
  <div
    v-if="respData!==null"
    class="auth-wrapper auth-v2"
  >
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :to="'/'+$route.params.url+'/login'"
      >
        <!-- <vuexy-logo /> -->
        <!-- <custom-logo /> -->
        <img
          v-if="respData.logo"
          :src="respData.logo"
          alt="ss"
          height="30"
        >
        <h2 class="brand-text text-primary ml-1">
          {{ respData.name }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-overlay
          id="overlay-background"
          :show="show"
          rounded="sm"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Welcome to') }} {{ respData.name }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('Please sign-in to your account') }}
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('Username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="$t('Username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Password") }}</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>{{ $t("Forgot Password") }}?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="remember"
                    name="checkbox-1"
                  >
                    {{ $t("Remember Me") }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t("Sign in") }}
                </b-button>
              </b-form>
            </validation-observer>

          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
// import CustomLogo from '@/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

// eslint-disable-next-line no-unused-vars
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/loginApplication'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, franchiseAbility, companyAbility, deliveryAbility, oneworkAbility, heroAbility,
} from '@/libs/acl/config'

const STORE_MODULE_NAME = 'loginApplication'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    // CustomLogo,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      remember: process.env.NODE_ENV === 'production' ? false : true,
      username: process.env.NODE_ENV === 'production' ? '' : 'adminprem',
      password: process.env.NODE_ENV === 'production' ? '' : '123456',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      show: false,
      type: 'staff',
      // validation rules
      required,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }
    // const obj = {
    //   currentPage: this.currentPage,
    //   pageSize: this.perPage,
    // }
    store
      .dispatch(`${STORE_MODULE_NAME}/getApplication`, this.$route.params.url)
      .then(result => {
        console.log('fetch success :', result)
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$router.push({ name: 'error-404' })

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.show = true

          useJwt
            .login({
              username: this.username,
              password: this.password,
              remember: this.remember,
              type: this.type,
            })
            .then(response => {
              console.log('response.data')
              console.log(response.data)
              // const userData = response.data.data

              // eslint-disable-next-line no-unused-vars
              const { accessToken, userData, refreshToken } = response.data
              store
                .dispatch(`${STORE_MODULE_NAME}/authApplication`, { token: accessToken, applicationId: this.respData._id })
                .then(result => {
                  this.show = false
                  console.log('fetch success :', result.data.data)
                  useJwt.setToken(accessToken)
                  useJwt.setRefreshToken(refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('systemOption', result.data.data.config)
                  localStorage.setItem('role', result.data.data.role)
                  if (result.data.data.serviceAreaId !== null) {
                    localStorage.setItem('serviceAreaId', result.data.data.serviceAreaId.toString())
                  }
                  localStorage.setItem('franchiseId', result.data.data.applications.franchiseId._id)
                  localStorage.setItem('franchiseData', JSON.stringify(result.data.data.applications.franchiseId))
                  localStorage.setItem('companyId', result.data.data.applications.companyId._id)
                  localStorage.setItem('companyData', JSON.stringify(result.data.data.applications.companyId))
                  const applicationData = result.data.data.applications
                  applicationData.companyId = applicationData.companyId._id
                  applicationData.franchiseId = applicationData.franchiseId._id
                  localStorage.setItem('applicationId', applicationData._id)
                  localStorage.setItem('applicationData', JSON.stringify(applicationData))
                  localStorage.setItem('tokenApplication', applicationData.token)
                  localStorage.setItem('applicationUrl', this.$route.params.url)

                  let menuService = ''
                  if (this.respData.appType === 'hero') {
                    if (result.data.data.role === 'admin') {
                      this.$ability.update(heroAbility())
                      localStorage.setItem('ability', JSON.stringify(heroAbility()))
                    } else {
                      this.$ability.update(result.data.data.ability)
                      localStorage.setItem('ability', JSON.stringify(result.data.data.ability))
                    }
                    menuService = 'hero'
                    store.commit('appConfig/UPDATE_MENU_SERVICE', menuService)
                  } else if (this.respData.appType === 'delivery') {
                    menuService = 'delivery'
                    store.commit('appConfig/UPDATE_MENU_SERVICE', menuService)
                  }
                  console.log(menuService)
                  // // // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router
                    .replace(getHomeRouteForLoggedInUser(menuService))
                    .then(() => {
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `${this.$t('Welcome')} ${userData.firstName} ${userData.lastName}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `${this.$t('You have successfully logged in')}!`,
                        },
                      })
                    }).catch(err => {
                      console.log(err)
                      this.show = false
                    })
                })
                .catch(error => {
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            })
            .catch(error => {
              this.show = false

              // this.$refs.loginForm.setErrors(error.response.data.message)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
