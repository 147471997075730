import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getApplication({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/applicationUrl/get?url=${item}`)
          .then(response => {
            console.log(response)
            commit('GET', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    authApplication({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/applicationUrl/auth?token=${item.token}&applicationId=${item.applicationId}`)
          .then(response => {
            console.log(response)
            // commit('GET', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
